import {ContractListPostData, ContractorRequestDocumentPostData, VZResponse} from '~/types/api'
import {
  ContractorDocumentsRequest,
  Contractor,
  ContractorListResponse,
  ContractorBalance, Project, ContractorOverpaid, Meta, ContractorOverpaidView, AvailableContractorOverpaid
} from '~/types/types'
import {httpNewApi, http, useVzFetch, cookieItems, httpApiV3} from '~/composables/useVzFetch';
import {useMemoize} from '@vueuse/core'

export const getContractors = (body: ContractListPostData, signal: AbortSignal | undefined = undefined) => {
  const token = useCookie(cookieItems.token)
  const action = token.value
      ? getPersonalContractors(body, signal)
      : useVzFetch(`${httpNewApi}/daData/contractor-list/get`, {
        method: 'POST',
        body,
        signal
      })

   return action.then((data) => {
    if (token.value) {
      return data
    }
    let contrs = []

    if (Array.isArray(data)) {
      contrs = data.reduce((arr, item) => {
        arr.push({
          inn: item.taxId,
          kpp: item.IEC,
          name: item.companyName
        })
        return arr
      }, [])
    }

    return {
      data: contrs,
      meta: {
        limit: 20,
        totalPage: 0,
        page: 1
      }
    }
  }) as Promise<ContractorListResponse>
}

// https://git.vozovoz.ru/projects/VOZ/repos/vozovoz/browse/rest-api/modules/v3/controllers/personal/contractor/setting.md#update
export const getContractorSetting = (contractorGuid: string): Promise<any> => {
  return useVzFetch(`${httpApiV3}/personal/contractor/setting/${contractorGuid}`, {
    method: 'GET'
  }) as any
}

export const putContractorSetting = (body: any, contractorGuid: string): Promise<any> => {
  return useVzFetch(`${httpApiV3}/personal/contractor/setting/${contractorGuid}`, {
    method: 'PUT',
    body
  }) as any
}

export const getPersonalContractors = useMemoize(
  (body: ContractListPostData, signal: AbortSignal|undefined = undefined): Promise<ContractorListResponse> => {
    return useVzFetch(`${httpNewApi}/personal/contractor-list/get`, {
      method: 'POST',
      body,
      signal
    }) as Promise<ContractorListResponse>
  }
)

export const removeContractor = (contractorGuid: string): Promise<Contractor> => {
  return useVzFetch(`${httpNewApi}/personal/contractor/remove`, {
    method: 'POST',
    body: {
      contractorGuid
    }
  })
}

export const setIndividualAccess = (contractorGuid: string, attachment = undefined): Promise<any> => {
  return useVzFetch(`${httpNewApi}/personal/contractor/inquire-access`, {
    method: 'POST',
    body: {contractorGuid, attachment}
  })
}

export const createContractor = (data: any): Promise<any> => {
  return useVzFetch(`${httpNewApi}/personal/contractor/create`, {
    method: 'POST',
    body: {...data}
  })
}

export function getContractor(contractorGuid: string, manager?: boolean, useContact?: boolean) {
  return useVzFetch<Contractor>(`${httpNewApi}/personal/contractor/get`, {
    method: 'POST',
    body: {
      contractorGuid,
      detailedBalance: true,
      manager,
      useContact
    }
  })
}

export const confirmPhone = (phone: string, code: string): any => {
  return useVzFetch(`${httpNewApi}/phone/confirm`, {
    method: 'POST',
    body: {
      phone,
      code
    }
  })
}

export const getContractorDocuments = (filter: ContractorDocumentsRequest): any => {
  return useVzFetch(`${httpNewApi}/personal/contractor/get-document-list`, {
    method: 'POST',
    body: {
      ...filter
    }
  })
}

export const getContractorContacts = (contractorGuid: string): any => {
  return useVzFetch(`${httpNewApi}/personal/contractor/get-contact-list`, {
    method: 'POST',
    body: {contractorGuid}
  })
}

export const getClaimDocuments = (filter: any): Promise<any> => {
  return useVzFetch(`${httpNewApi}/personal/claim-list/get`, {
    method: 'POST',
    body: {
      ...filter
    }
  })
}

export const contractorUpdateContactList = (data: any): Promise<any> => {
  return useVzFetch(`${httpNewApi}/personal/contractor/update-contact-list`, {
    method: 'POST',
    body: {
      ...data
    }
  })
}

export const updateContractor = (body: any): Promise<any> => {
  return useVzFetch(`${httpNewApi}/personal/contractor/update`, {
    method: 'POST',
    body
  })
}

export const getContractorProject = useMemoize((): Promise<Project[]> => {
  return useVzFetch(`${httpApiV3}/personal/contractor/project`, {
    method: 'GET'
  })
})

export const getContractorEdo = (contractorGuid: string): Promise<ContractorBalance> => {
  return useVzFetch(`${httpNewApi}/personal/contractor/request-workflow-access`, {
    method: 'POST',
    body: {
      contractorGuid
    }
  })
}

export const getPaymentLinkRedirection = (action: any, data: any): any => {
  return useVzFetch(`${httpNewApi}${action}`, {
    method: 'POST',
    body: {
      ...data
    }
  })
}

export const getPersonalOrderPaymentInfo = (data: any) => {
  return useVzFetch(`${http}/payment/lk`, {
    method: 'POST',
    body: {
      ...data
    }
  })
}

export const getContractorBalance = (contractorGuid: string): Promise<any> => {
  return useVzFetch(`${httpNewApi}/personal/contractor/get-balance`, {
    method: 'POST',
    body: {contractorGuid}
  })
}

export const getContractorBalanceV3 = (contractorGuid: string): Promise<any> => {
  return useVzFetch(`${httpApiV3}/personal/contractor/balance/${contractorGuid}`, {
    method: 'GET'
  })
}


export const contractorDropAccess = (contractorGuid: string): Promise<any> => {
  return useVzFetch(`${httpNewApi}/personal/contractor/drop-access`, {
    method: 'POST',
    body: {contractorGuid}
  })
}

export const uploadDocAccess = (body: FormData): Promise<any> => {
  return useVzFetch(`${httpNewApi}/file/upload`, {
    method: "POST",
    body
  })
}

export const requestDocumentReport = (body: ContractorRequestDocumentPostData): Promise<any> => {
  return useVzFetch(`${httpNewApi}/personal/contractor/request-document-report`, {
    method: 'POST',
    body
  }) as any
}

export const contractorUpdateFavorite = (body: any) => {
  return useVzFetch(`${httpNewApi}/personal/contractor/set-favorite`, {
    method: 'POST',
    body
  })
}

export function getContractorOverpaidList(contractorGuid: string, meta: Partial<Pick<Meta, 'page'|'perPage'>> = {}) {
  return useVzFetch<{ data: ContractorOverpaidView[] }>(`${httpApiV3}/personal/contractor/overpaid/request`, {
    method: 'GET', params: { contractorGuid, ...meta }
  })
}

export function createContractorOverpaid(body: ContractorOverpaid) {
  return useVzFetch<VZResponse<{ requestId: string }>>(`${httpApiV3}/personal/contractor/overpaid/request`, {
    method: 'POST', body
  })
}

export function getAvailableContractorOverpaid(contractorGuid: string) {
  return useVzFetch<{ data: AvailableContractorOverpaid[] }>(`${httpApiV3}/personal/contractor/overpaid/available`, {
    method: 'GET', params: { contractorGuid }
  })
}

export const getPhoneCode = (body: any) => {
  return useVzFetch(`${httpNewApi}/phone/confirm`, {
    method: 'POST',
    body
  }) as any
}
